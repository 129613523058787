/**
 * @generated SignedSource<<cf183cc9a04403ff9b55bab30872811b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ComposeMessageChannel = "COMPOSE_MESSAGE_CHANNEL_EMAIL" | "COMPOSE_MESSAGE_CHANNEL_TEXT" | "COMPOSE_MESSAGE_CHANNEL_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useJourneyMessages_TargetingCommon_JourneyConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly internalId: string;
      readonly journeyRevisions: ReadonlyArray<{
        readonly id: string;
        readonly messages: ReadonlyArray<{
          readonly composeMessage: {
            readonly channel: ComposeMessageChannel;
            readonly id: string;
            readonly internalId: number;
            readonly name: string;
          } | null;
        }>;
      }> | null;
      readonly name: string;
    };
  }>;
  readonly " $fragmentType": "useJourneyMessages_TargetingCommon_JourneyConnection";
};
export type useJourneyMessages_TargetingCommon_JourneyConnection$key = {
  readonly " $data"?: useJourneyMessages_TargetingCommon_JourneyConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"useJourneyMessages_TargetingCommon_JourneyConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useJourneyMessages_TargetingCommon_JourneyConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JourneyEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Journey",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "JourneyRevision",
              "kind": "LinkedField",
              "name": "journeyRevisions",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "JourneyMessage",
                  "kind": "LinkedField",
                  "name": "messages",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ComposeMessage",
                      "kind": "LinkedField",
                      "name": "composeMessage",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "channel",
                          "storageKey": null
                        },
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "JourneyConnection",
  "abstractKey": null
};
})();

(node as any).hash = "fe7f00767a9d9e17aeb7c4031e012ab7";

export default node;
