/**
 * @generated SignedSource<<19a9bbf109c7fe2d09f96d5bb0d3fa61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsageTable_SegmentCount_TargetingCommon_Segment$data = {
  readonly segmentCount: {
    readonly " $fragmentSpreads": FragmentRefs<"downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload">;
  } | null;
  readonly " $fragmentType": "UsageTable_SegmentCount_TargetingCommon_Segment";
};
export type UsageTable_SegmentCount_TargetingCommon_Segment$key = {
  readonly " $data"?: UsageTable_SegmentCount_TargetingCommon_Segment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsageTable_SegmentCount_TargetingCommon_Segment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "activeSubscribers",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageTable_SegmentCount_TargetingCommon_Segment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SegmentCountPayload",
      "kind": "LinkedField",
      "name": "segmentCount",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SegmentCountItem",
              "kind": "LinkedField",
              "name": "email",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SegmentCountItem",
              "kind": "LinkedField",
              "name": "sms",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SegmentCountItem",
              "kind": "LinkedField",
              "name": "smsAndEmail",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Segment",
  "abstractKey": null
};
})();

(node as any).hash = "735106de2ee70ea033f72c97e802fd39";

export default node;
