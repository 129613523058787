/**
 * @generated SignedSource<<a72a426e7312566d1a1eb50a72263af2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCustomAttributes_TargetingCommon_Attributes_query$data = {
  readonly company: {
    readonly id?: string;
    readonly userPropertyDefinitionsV2?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly customPropertyId?: {
            readonly id: string;
          };
          readonly id?: string;
          readonly " $fragmentSpreads": FragmentRefs<"useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition">;
        };
      }>;
    } | null;
  };
  readonly " $fragmentType": "useCustomAttributes_TargetingCommon_Attributes_query";
};
export type useCustomAttributes_TargetingCommon_Attributes_query$key = {
  readonly " $data"?: useCustomAttributes_TargetingCommon_Attributes_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCustomAttributes_TargetingCommon_Attributes_query">;
};

import useCustomAttributes_TargetingCommon_Attributes_RefetchableFragment_graphql from './useCustomAttributes_TargetingCommon_Attributes_RefetchableFragment.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "company",
  "userPropertyDefinitionsV2"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomPropertyID",
  "kind": "LinkedField",
  "name": "customPropertyId",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "defaultValue": 100,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": useCustomAttributes_TargetingCommon_Attributes_RefetchableFragment_graphql
    }
  },
  "name": "useCustomAttributes_TargetingCommon_Attributes_query",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": "userPropertyDefinitionsV2",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                      "includeDeleted": false,
                      "includeInternalProperties": false,
                      "source": "USER_PROPERTY_SOURCE_CUSTOM"
                    }
                  }
                ],
                "concreteType": "UserPropertyDefinitionConnectionV2",
                "kind": "LinkedField",
                "name": "__useCustomAttributes_TargetingCommon_Attributes_connection_userPropertyDefinitionsV2_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserPropertyDefinitionEdgeV2",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/)
                            ],
                            "type": "CustomPropertyDefinition",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition",
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/),
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "propertyType",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "created",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "type": "CustomPropertyDefinition",
                                "abstractKey": null
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "__useCustomAttributes_TargetingCommon_Attributes_connection_userPropertyDefinitionsV2_connection(filter:{\"includeDeleted\":false,\"includeInternalProperties\":false,\"source\":\"USER_PROPERTY_SOURCE_CUSTOM\"})"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "company"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "5df74e9945402022d6d06a98c7adc3f2";

export default node;
