/**
 * @generated SignedSource<<0b0adf20f20fe3f7ffdc605e7d6491e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomPropertyType = "TYPE_ARRAY_OBJECT" | "TYPE_BOOL" | "TYPE_DATE" | "TYPE_DATETIME" | "TYPE_ENUM" | "TYPE_LONG" | "TYPE_NUMBER" | "TYPE_OBJECT" | "TYPE_STRING" | "TYPE_TIMESTAMP" | "TYPE_UNKNOWN" | "%future added value";
export type ExistingCustomAttributeDialog_TargetingCommon_Query$variables = {
  id: string;
};
export type ExistingCustomAttributeDialog_TargetingCommon_Query$data = {
  readonly customAttribute: {
    readonly " $fragmentSpreads": FragmentRefs<"ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition" | "formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition">;
  };
};
export type ExistingCustomAttributeDialog_TargetingCommon_Query$rawResponse = {
  readonly customAttribute: {
    readonly __typename: "CustomPropertyDefinition";
    readonly id: string;
    readonly name: string;
    readonly propertyType: CustomPropertyType;
    readonly typeDefinition: {
      readonly __typename: "EnumeratedPropertyDefinition";
      readonly enumeratedValues: ReadonlyArray<{
        readonly isDeleted: boolean;
        readonly value: string;
        readonly valueId: {
          readonly id: string;
        };
      }>;
    } | {
      readonly __typename: string;
    } | null;
    readonly usages: ReadonlyArray<{
      readonly id: string;
    }>;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type ExistingCustomAttributeDialog_TargetingCommon_Query = {
  rawResponse: ExistingCustomAttributeDialog_TargetingCommon_Query$rawResponse;
  response: ExistingCustomAttributeDialog_TargetingCommon_Query$data;
  variables: ExistingCustomAttributeDialog_TargetingCommon_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "propertyType",
  "storageKey": null
},
v6 = [
  (v2/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "EnumeratedValue",
  "kind": "LinkedField",
  "name": "enumeratedValues",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EnumeratedPropertyValueID",
      "kind": "LinkedField",
      "name": "valueId",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExistingCustomAttributeDialog_TargetingCommon_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "customAttribute",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition",
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "typeDefinition",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "kind": "InlineDataFragmentSpread",
                              "name": "formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition",
                              "selections": [
                                (v3/*: any*/),
                                (v7/*: any*/)
                              ],
                              "args": null,
                              "argumentDefinitions": []
                            }
                          ],
                          "type": "EnumeratedPropertyDefinition",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition"
                }
              ],
              "type": "CustomPropertyDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "customAttribute"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExistingCustomAttributeDialog_TargetingCommon_Query",
    "selections": [
      {
        "alias": "customAttribute",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "typeDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "type": "EnumeratedPropertyDefinition",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Segment",
                "kind": "LinkedField",
                "name": "usages",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "type": "CustomPropertyDefinition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eceed6e8f372dacbbd662fdc24adcaee",
    "id": null,
    "metadata": {},
    "name": "ExistingCustomAttributeDialog_TargetingCommon_Query",
    "operationKind": "query",
    "text": "query ExistingCustomAttributeDialog_TargetingCommon_Query(\n  $id: ID!\n) {\n  customAttribute: node(id: $id) {\n    __typename\n    ... on CustomPropertyDefinition {\n      ...formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition\n      ...ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition\n    }\n    id\n  }\n}\n\nfragment ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition on CustomPropertyDefinition {\n  usages {\n    id\n  }\n  id\n}\n\nfragment formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition on CustomPropertyDefinition {\n  id\n  __typename\n  name\n  propertyType\n  typeDefinition {\n    __typename\n    ... on EnumeratedPropertyDefinition {\n      ...formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition\n    }\n  }\n}\n\nfragment formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition on EnumeratedPropertyDefinition {\n  __typename\n  enumeratedValues {\n    isDeleted\n    value\n    valueId {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d90b4954c031ebeb030cc6a249faf4fe";

export default node;
