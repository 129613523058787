/**
 * @generated SignedSource<<2b65463d3f6bc7c8632ae6d15a0e6c0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PostalCodeInputList = {
  postalCodes?: ReadonlyArray<PostalCodeInput> | null;
};
export type PostalCodeInput = {
  postalCode: string;
};
export type ZipcodeTargetingCommonQuery$variables = {
  countryId?: string | null;
  zipcodes?: PostalCodeInputList | null;
};
export type ZipcodeTargetingCommonQuery$data = {
  readonly validationResult: {
    readonly invalid: ReadonlyArray<{
      readonly postalCode: string;
    }>;
    readonly valid: ReadonlyArray<{
      readonly countrySubdivision: {
        readonly isoCode: string;
      } | null;
      readonly postalCode: string;
    }>;
  } | null;
};
export type ZipcodeTargetingCommonQuery$rawResponse = {
  readonly validationResult: {
    readonly invalid: ReadonlyArray<{
      readonly postalCode: string;
    }>;
    readonly valid: ReadonlyArray<{
      readonly countrySubdivision: {
        readonly id: string;
        readonly isoCode: string;
      } | null;
      readonly id: string;
      readonly postalCode: string;
    }>;
  } | null;
};
export type ZipcodeTargetingCommonQuery = {
  rawResponse: ZipcodeTargetingCommonQuery$rawResponse;
  response: ZipcodeTargetingCommonQuery$data;
  variables: ZipcodeTargetingCommonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "zipcodes"
},
v2 = [
  {
    "kind": "Variable",
    "name": "countryId",
    "variableName": "countryId"
  },
  {
    "kind": "Variable",
    "name": "postalCodeInput",
    "variableName": "zipcodes"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v4 = {
  "alias": "invalid",
  "args": null,
  "concreteType": "InvalidPostalCode",
  "kind": "LinkedField",
  "name": "invalidPostalCodes",
  "plural": true,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isoCode",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ZipcodeTargetingCommonQuery",
    "selections": [
      {
        "alias": "validationResult",
        "args": (v2/*: any*/),
        "concreteType": "ValidatePostalCodesResponse",
        "kind": "LinkedField",
        "name": "validatePostalCodes",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": "valid",
            "args": null,
            "concreteType": "PostalCode",
            "kind": "LinkedField",
            "name": "postalCodes",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CountrySubdivision",
                "kind": "LinkedField",
                "name": "countrySubdivision",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ZipcodeTargetingCommonQuery",
    "selections": [
      {
        "alias": "validationResult",
        "args": (v2/*: any*/),
        "concreteType": "ValidatePostalCodesResponse",
        "kind": "LinkedField",
        "name": "validatePostalCodes",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": "valid",
            "args": null,
            "concreteType": "PostalCode",
            "kind": "LinkedField",
            "name": "postalCodes",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CountrySubdivision",
                "kind": "LinkedField",
                "name": "countrySubdivision",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1b8ef841e9f20d8d361ee90813976dd",
    "id": null,
    "metadata": {},
    "name": "ZipcodeTargetingCommonQuery",
    "operationKind": "query",
    "text": "query ZipcodeTargetingCommonQuery(\n  $zipcodes: PostalCodeInputList\n  $countryId: String\n) {\n  validationResult: validatePostalCodes(postalCodeInput: $zipcodes, countryId: $countryId) {\n    invalid: invalidPostalCodes {\n      postalCode\n    }\n    valid: postalCodes {\n      postalCode\n      countrySubdivision {\n        isoCode\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "10d68105664f70e553ea3b8f82cd90e4";

export default node;
