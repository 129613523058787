/**
 * @generated SignedSource<<a7525bd64687819fef5c7f017049b275>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DistanceUnit = "DISTANCE_UNIT_KILOMETER" | "DISTANCE_UNIT_METER" | "DISTANCE_UNIT_MILE" | "DISTANCE_UNIT_UNKNOWN" | "%future added value";
export type DistanceTargetingCommonQuery$variables = {
  countryIsoCode: string;
  distance: number;
  distanceUnit: DistanceUnit;
  zipcode: string;
};
export type DistanceTargetingCommonQuery$data = {
  readonly postalCodes: {
    readonly aggregate: {
      readonly subdivisionTotal: number;
      readonly total: number;
    };
  } | null;
};
export type DistanceTargetingCommonQuery$rawResponse = {
  readonly postalCodes: {
    readonly aggregate: {
      readonly subdivisionTotal: number;
      readonly total: number;
    };
  } | null;
};
export type DistanceTargetingCommonQuery = {
  rawResponse: DistanceTargetingCommonQuery$rawResponse;
  response: DistanceTargetingCommonQuery$data;
  variables: DistanceTargetingCommonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryIsoCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "distance"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "distanceUnit"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "zipcode"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "distanceUnit",
                "variableName": "distanceUnit"
              },
              {
                "kind": "Variable",
                "name": "value",
                "variableName": "distance"
              }
            ],
            "kind": "ObjectValue",
            "name": "distance"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "postalCode",
                "variableName": "zipcode"
              }
            ],
            "kind": "ObjectValue",
            "name": "origin"
          }
        ],
        "kind": "ObjectValue",
        "name": "adjacent"
      },
      {
        "kind": "Variable",
        "name": "countryIsoCode",
        "variableName": "countryIsoCode"
      }
    ],
    "concreteType": "PostalCodeConnection",
    "kind": "LinkedField",
    "name": "postalCodes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PostalCodeAggregate",
        "kind": "LinkedField",
        "name": "aggregate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subdivisionTotal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DistanceTargetingCommonQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DistanceTargetingCommonQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "357ac494ae164de3adb36681c522fab8",
    "id": null,
    "metadata": {},
    "name": "DistanceTargetingCommonQuery",
    "operationKind": "query",
    "text": "query DistanceTargetingCommonQuery(\n  $distanceUnit: DistanceUnit!\n  $distance: Float!\n  $zipcode: String!\n  $countryIsoCode: String!\n) {\n  postalCodes(adjacent: {distance: {distanceUnit: $distanceUnit, value: $distance}, origin: {postalCode: $zipcode}}, countryIsoCode: $countryIsoCode) {\n    aggregate {\n      subdivisionTotal\n      total\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8379154db1157bb0e4552bfca459b159";

export default node;
