/**
 * @generated SignedSource<<e56f6a25828ff31c391250d633fb88ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition$data = {
  readonly __typename: "EnumeratedPropertyDefinition";
  readonly enumeratedValues: ReadonlyArray<{
    readonly isDeleted: boolean;
    readonly value: string;
    readonly valueId: {
      readonly id: string;
    };
  }>;
  readonly " $fragmentType": "formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition";
};
export type formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition$key = {
  readonly " $data"?: formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition"
};

(node as any).hash = "55c27950f8f58ad2f6f12590e6f0a89a";

export default node;
