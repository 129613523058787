/**
 * @generated SignedSource<<4f1b5792f963d2ddeeda3b7c412e2691>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload$data = {
  readonly email: {
    readonly activeSubscribers: number;
  } | null;
  readonly sms: {
    readonly activeSubscribers: number;
  };
  readonly smsAndEmail: {
    readonly activeSubscribers: number;
  } | null;
  readonly " $fragmentType": "downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload";
};
export type downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload$key = {
  readonly " $data"?: downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload$data;
  readonly " $fragmentSpreads": FragmentRefs<"downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload"
};

(node as any).hash = "1d5a6f96f85951f92a956a1ad72f9b74";

export default node;
