/**
 * @generated SignedSource<<9e1ae0c21b35edd481199680bfef942a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CustomPropertyType = "TYPE_ARRAY_OBJECT" | "TYPE_BOOL" | "TYPE_DATE" | "TYPE_DATETIME" | "TYPE_ENUM" | "TYPE_LONG" | "TYPE_NUMBER" | "TYPE_OBJECT" | "TYPE_STRING" | "TYPE_TIMESTAMP" | "TYPE_UNKNOWN" | "%future added value";
export type PostCustomPropertyDefinitionInput = {
  enumValues?: ReadonlyArray<string> | null;
  isDeleted?: boolean | null;
  name: string;
  propertyType: CustomPropertyType;
};
export type UpdateCustomAttributeTargetingCommonMutation$variables = {
  companyId: string;
  customPropertyId: string;
  newFields: PostCustomPropertyDefinitionInput;
};
export type UpdateCustomAttributeTargetingCommonMutation$data = {
  readonly updateCustomPropertyDefinition: {
    readonly customPropertyDefinition: {
      readonly customPropertyId: {
        readonly internalId: string;
      };
      readonly id: string;
      readonly name: string;
      readonly propertyType: CustomPropertyType;
      readonly typeDefinition: {
        readonly enumeratedValues?: ReadonlyArray<{
          readonly isDeleted: boolean;
          readonly value: string;
          readonly valueId: {
            readonly id: string;
          };
        }>;
      } | null;
    };
  } | null;
};
export type UpdateCustomAttributeTargetingCommonMutation$rawResponse = {
  readonly updateCustomPropertyDefinition: {
    readonly customPropertyDefinition: {
      readonly customPropertyId: {
        readonly internalId: string;
      };
      readonly id: string;
      readonly name: string;
      readonly propertyType: CustomPropertyType;
      readonly typeDefinition: {
        readonly __typename: "EnumeratedPropertyDefinition";
        readonly enumeratedValues: ReadonlyArray<{
          readonly isDeleted: boolean;
          readonly value: string;
          readonly valueId: {
            readonly id: string;
          };
        }>;
      } | {
        readonly __typename: string;
      } | null;
    };
  } | null;
};
export type UpdateCustomAttributeTargetingCommonMutation = {
  rawResponse: UpdateCustomAttributeTargetingCommonMutation$rawResponse;
  response: UpdateCustomAttributeTargetingCommonMutation$data;
  variables: UpdateCustomAttributeTargetingCommonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customPropertyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newFields"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "companyId",
        "variableName": "companyId"
      },
      {
        "kind": "Variable",
        "name": "customPropertyDefinitionId",
        "variableName": "customPropertyId"
      },
      {
        "kind": "Variable",
        "name": "updatedFields",
        "variableName": "newFields"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "propertyType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomPropertyID",
  "kind": "LinkedField",
  "name": "customPropertyId",
  "plural": false,
  "selections": [
    {
      "alias": "internalId",
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EnumeratedValue",
      "kind": "LinkedField",
      "name": "enumeratedValues",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDeleted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EnumeratedPropertyValueID",
          "kind": "LinkedField",
          "name": "valueId",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EnumeratedPropertyDefinition",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCustomAttributeTargetingCommonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomPropertyDefinitionPayload",
        "kind": "LinkedField",
        "name": "updateCustomPropertyDefinition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomPropertyDefinition",
            "kind": "LinkedField",
            "name": "customPropertyDefinition",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "typeDefinition",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCustomAttributeTargetingCommonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomPropertyDefinitionPayload",
        "kind": "LinkedField",
        "name": "updateCustomPropertyDefinition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomPropertyDefinition",
            "kind": "LinkedField",
            "name": "customPropertyDefinition",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "typeDefinition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d4c6d4af604f43a0d636d30f0a310cf5",
    "id": null,
    "metadata": {},
    "name": "UpdateCustomAttributeTargetingCommonMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCustomAttributeTargetingCommonMutation(\n  $companyId: ID!\n  $customPropertyId: ID!\n  $newFields: PostCustomPropertyDefinitionInput!\n) {\n  updateCustomPropertyDefinition(input: {companyId: $companyId, customPropertyDefinitionId: $customPropertyId, updatedFields: $newFields}) {\n    customPropertyDefinition {\n      id\n      name\n      propertyType\n      customPropertyId {\n        internalId: id\n      }\n      typeDefinition {\n        __typename\n        ... on EnumeratedPropertyDefinition {\n          enumeratedValues {\n            isDeleted\n            value\n            valueId {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0cdc1cf715c5515f01c5539eba8d8e2";

export default node;
