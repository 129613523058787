/**
 * @generated SignedSource<<4477b2410376069e815141ce534e2cd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomPropertyType = "TYPE_ARRAY_OBJECT" | "TYPE_BOOL" | "TYPE_DATE" | "TYPE_DATETIME" | "TYPE_ENUM" | "TYPE_LONG" | "TYPE_NUMBER" | "TYPE_OBJECT" | "TYPE_STRING" | "TYPE_TIMESTAMP" | "TYPE_UNKNOWN" | "%future added value";
export type CustomAttributeDelete_CustomAttributeDelete_TargetingCommon_Query$variables = {
  customPropertyID: string;
};
export type CustomAttributeDelete_CustomAttributeDelete_TargetingCommon_Query$data = {
  readonly node: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition" | "hasSegmentUsages_TargetingCommon_CustomPropertyDefinition">;
  } | null;
};
export type CustomAttributeDelete_CustomAttributeDelete_TargetingCommon_Query$rawResponse = {
  readonly node: {
    readonly __typename: "CustomPropertyDefinition";
    readonly id: string;
    readonly name: string;
    readonly propertyType: CustomPropertyType;
    readonly typeDefinition: {
      readonly __typename: "EnumeratedPropertyDefinition";
      readonly enumeratedValues: ReadonlyArray<{
        readonly isDeleted: boolean;
        readonly value: string;
        readonly valueId: {
          readonly id: string;
        };
      }>;
    } | {
      readonly __typename: string;
    } | null;
    readonly usages: ReadonlyArray<{
      readonly id: string;
    }>;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type CustomAttributeDelete_CustomAttributeDelete_TargetingCommon_Query = {
  rawResponse: CustomAttributeDelete_CustomAttributeDelete_TargetingCommon_Query$rawResponse;
  response: CustomAttributeDelete_CustomAttributeDelete_TargetingCommon_Query$data;
  variables: CustomAttributeDelete_CustomAttributeDelete_TargetingCommon_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customPropertyID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "customPropertyID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Segment",
  "kind": "LinkedField",
  "name": "usages",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "propertyType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "EnumeratedValue",
  "kind": "LinkedField",
  "name": "enumeratedValues",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EnumeratedPropertyValueID",
      "kind": "LinkedField",
      "name": "valueId",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomAttributeDelete_CustomAttributeDelete_TargetingCommon_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "hasSegmentUsages_TargetingCommon_CustomPropertyDefinition",
                "selections": [
                  (v4/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              },
              {
                "kind": "InlineDataFragmentSpread",
                "name": "formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition",
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "typeDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition",
                            "selections": [
                              (v5/*: any*/),
                              (v8/*: any*/)
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "type": "EnumeratedPropertyDefinition",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "type": "CustomPropertyDefinition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomAttributeDelete_CustomAttributeDelete_TargetingCommon_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "typeDefinition",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "type": "EnumeratedPropertyDefinition",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CustomPropertyDefinition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0641f4e733d2188df93be50ae82622db",
    "id": null,
    "metadata": {},
    "name": "CustomAttributeDelete_CustomAttributeDelete_TargetingCommon_Query",
    "operationKind": "query",
    "text": "query CustomAttributeDelete_CustomAttributeDelete_TargetingCommon_Query(\n  $customPropertyID: ID!\n) {\n  node(id: $customPropertyID) {\n    __typename\n    id\n    ... on CustomPropertyDefinition {\n      ...hasSegmentUsages_TargetingCommon_CustomPropertyDefinition\n      ...formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition\n    }\n  }\n}\n\nfragment formExistingCustomAttributeDefinitionTargetingCommonFragment_customPropertyDefinition on CustomPropertyDefinition {\n  id\n  __typename\n  name\n  propertyType\n  typeDefinition {\n    __typename\n    ... on EnumeratedPropertyDefinition {\n      ...formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition\n    }\n  }\n}\n\nfragment formExistingCustomAttributeEnumValuesTargetingCommonFragment_EnumeratedPropertyDefinition on EnumeratedPropertyDefinition {\n  __typename\n  enumeratedValues {\n    isDeleted\n    value\n    valueId {\n      id\n    }\n  }\n}\n\nfragment hasSegmentUsages_TargetingCommon_CustomPropertyDefinition on CustomPropertyDefinition {\n  usages {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8dcf2282ff26cf1fc41f5ec2b275013f";

export default node;
