/**
 * @generated SignedSource<<b7855805f0df881acc8d79649f8dc1fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery$variables = {
  id: string;
};
export type CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"CustomAttributeUsage_TableData_TargetingCommon_CustomPropertyDefinition">;
  } | null;
};
export type CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery = {
  response: CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery$data;
  variables: CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "activeSubscribers",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomAttributeUsage_TableData_TargetingCommon_CustomPropertyDefinition"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Segment",
                "kind": "LinkedField",
                "name": "usages",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "internalId",
                    "storageKey": null
                  },
                  {
                    "if": null,
                    "kind": "Defer",
                    "label": "UsageTable_TableRow_TargetingCommon_Segment$defer$custom-attr-usage-segment-count",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SegmentCountPayload",
                        "kind": "LinkedField",
                        "name": "segmentCount",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SegmentCountItem",
                            "kind": "LinkedField",
                            "name": "email",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SegmentCountItem",
                            "kind": "LinkedField",
                            "name": "sms",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SegmentCountItem",
                            "kind": "LinkedField",
                            "name": "smsAndEmail",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CustomPropertyDefinition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bb23db10d677ffaeac8500917038cc23",
    "id": null,
    "metadata": {},
    "name": "CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery",
    "operationKind": "query",
    "text": "query CustomAttributeUsage_TableData_TargetingCommon_RefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...CustomAttributeUsage_TableData_TargetingCommon_CustomPropertyDefinition\n    id\n  }\n}\n\nfragment CustomAttributeUsage_TableData_TargetingCommon_CustomPropertyDefinition on CustomPropertyDefinition {\n  ...UsageTable_TargetingCommon_CustomPropertyDefinition\n  id\n}\n\nfragment UsageTable_SegmentCount_TargetingCommon_Segment on Segment {\n  segmentCount {\n    ...downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload\n  }\n}\n\nfragment UsageTable_TableRow_TargetingCommon_Segment on Segment {\n  id\n  name\n  internalId\n  ...UsageTable_SegmentCount_TargetingCommon_Segment @defer(label: \"UsageTable_TableRow_TargetingCommon_Segment$defer$custom-attr-usage-segment-count\")\n}\n\nfragment UsageTable_TargetingCommon_CustomPropertyDefinition on CustomPropertyDefinition {\n  id\n  usages {\n    id\n    ...UsageTable_TableRow_TargetingCommon_Segment\n  }\n}\n\nfragment downloadSegmentUsages_getSubscriberCount_TargetingCommon_SegmentCountPayload on SegmentCountPayload {\n  email {\n    activeSubscribers\n  }\n  sms {\n    activeSubscribers\n  }\n  smsAndEmail {\n    activeSubscribers\n  }\n}\n"
  }
};
})();

(node as any).hash = "b4804eaa6d7a4fc1994978d97c801dd0";

export default node;
