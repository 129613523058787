/**
 * @generated SignedSource<<2c158aa79e90ab1c425ff247d42bb2c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AIBrandVoiceAllCapsUsage = "ALL_CAPS_USAGE_ADAPTIVE" | "ALL_CAPS_USAGE_FREQUENTLY" | "ALL_CAPS_USAGE_NEVER" | "ALL_CAPS_USAGE_SPARINGLY" | "%future added value";
export type AIBrandVoiceEmojiFrequency = "EMOJI_FREQUENCY_ADAPTIVE" | "EMOJI_FREQUENCY_FREQUENTLY" | "EMOJI_FREQUENCY_NEVER" | "EMOJI_FREQUENCY_SPARINGLY" | "%future added value";
export type AIBrandVoiceProductTitleFormatting = "PRODUCT_TITLE_FORMATTING_LOWER_CASE" | "PRODUCT_TITLE_FORMATTING_TITLE_CASE" | "PRODUCT_TITLE_FORMATTING_UPPER_CASE" | "%future added value";
export type AIBrandVoiceUrgency = "URGENCY_ADAPTIVE" | "URGENCY_FREQUENTLY" | "URGENCY_NEVER" | "URGENCY_SPARINGLY" | "%future added value";
export type OpenAIModel = "OPENAI_MODEL_GPT_3_5_TURBO" | "OPENAI_MODEL_GPT_4" | "OPENAI_MODEL_GPT_4_300_TEST" | "OPENAI_MODEL_GPT_4_O" | "OPENAI_MODEL_GPT_4_TURBO" | "OPENAI_MODEL_UNKNOWN" | "OPEN_AI_MODEL_GPT_4_0_MINI" | "%future added value";
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type TemplateRole = "TEMPLATE_ROLE_SYSTEM" | "TEMPLATE_ROLE_UNKNOWN" | "TEMPLATE_ROLE_USER" | "%future added value";
export type GenerateAIJourneyTextInput = {
  brandVoiceSettings?: AIBrandVoiceSettingsInputType | null;
  companyId: string;
  inputs?: ReadonlyArray<ChatMessageAdminToolInput> | null;
  metadata: GenerateAIJourneyTextMetadata;
  useMessageEvaluation?: boolean | null;
};
export type AIBrandVoiceSettingsInputType = {
  allCapsCount?: number | null;
  allCapsUsage?: AIBrandVoiceAllCapsUsage | null;
  characterCount?: number | null;
  emojiCount?: number | null;
  emojiFrequency?: AIBrandVoiceEmojiFrequency | null;
  productTitleFormatting?: AIBrandVoiceProductTitleFormatting | null;
  toneKeywords?: ReadonlyArray<string> | null;
  urgency?: AIBrandVoiceUrgency | null;
  wordCount?: number | null;
};
export type ChatMessageAdminToolInput = {
  content: string;
  role: TemplateRole;
};
export type GenerateAIJourneyTextMetadata = {
  n?: number | null;
  origin?: string | null;
  templateInput: GenerativeAITextTemplateInput;
  vendorOptions?: TextGenVendorOptions | null;
};
export type GenerativeAITextTemplateInput = {
  templateName: string;
  version: string;
};
export type TextGenVendorOptions = {
  vendorOptions?: TextGenVendorOptionsVendorOptions | null;
};
export type TextGenVendorOptionsVendorOptions = {
  openaiOptions?: OpenAIOptions | null;
};
export type OpenAIOptions = {
  model: OpenAIModel;
};
export type SegmentAssistant_TargetingCommon_Mutation$variables = {
  input: GenerateAIJourneyTextInput;
};
export type SegmentAssistant_TargetingCommon_Mutation$data = {
  readonly generateAIJourneyText: {
    readonly generatedTextResponses?: ReadonlyArray<{
      readonly aiGeneratedTextMetadata: ReadonlyArray<{
        readonly key: string;
        readonly value: string;
      }>;
      readonly id: string;
      readonly text: string;
    }>;
    readonly message?: string;
    readonly status?: StandardErrorStatus;
    readonly title?: string;
  } | null;
};
export type SegmentAssistant_TargetingCommon_Mutation$rawResponse = {
  readonly generateAIJourneyText: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
  } | {
    readonly __typename: "GenerateAIJourneyTextSuccess";
    readonly generatedTextResponses: ReadonlyArray<{
      readonly aiGeneratedTextMetadata: ReadonlyArray<{
        readonly key: string;
        readonly value: string;
      }>;
      readonly id: string;
      readonly text: string;
    }>;
  } | {
    readonly __typename: string;
  } | null;
};
export type SegmentAssistant_TargetingCommon_Mutation = {
  rawResponse: SegmentAssistant_TargetingCommon_Mutation$rawResponse;
  response: SegmentAssistant_TargetingCommon_Mutation$data;
  variables: SegmentAssistant_TargetingCommon_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "DefaultErrorFailure",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AIGeneratedText",
      "kind": "LinkedField",
      "name": "generatedTextResponses",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AiGeneratedTextMetadataEntry",
          "kind": "LinkedField",
          "name": "aiGeneratedTextMetadata",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GenerateAIJourneyTextSuccess",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SegmentAssistant_TargetingCommon_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "generateAIJourneyText",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SegmentAssistant_TargetingCommon_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "generateAIJourneyText",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78e57ec8af238e317eafe54d8f146b3a",
    "id": null,
    "metadata": {},
    "name": "SegmentAssistant_TargetingCommon_Mutation",
    "operationKind": "mutation",
    "text": "mutation SegmentAssistant_TargetingCommon_Mutation(\n  $input: GenerateAIJourneyTextInput!\n) {\n  generateAIJourneyText(input: $input) {\n    __typename\n    ... on DefaultErrorFailure {\n      message\n      status\n      title\n    }\n    ... on GenerateAIJourneyTextSuccess {\n      generatedTextResponses {\n        id\n        text\n        aiGeneratedTextMetadata {\n          key\n          value\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d00957dd998e0ab43d0d03be036f92cb";

export default node;
