/**
 * @generated SignedSource<<0022f0de478c5c6d74ab915f3eb6964e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CampaignMessageGroupStatus = "CAMPAIGN_MESSAGE_GROUP_STATUS_ATTENTIVE_REVIEW" | "CAMPAIGN_MESSAGE_GROUP_STATUS_CANCELLED" | "CAMPAIGN_MESSAGE_GROUP_STATUS_DRAFT" | "CAMPAIGN_MESSAGE_GROUP_STATUS_NEEDS_COUPONS" | "CAMPAIGN_MESSAGE_GROUP_STATUS_PAUSED" | "CAMPAIGN_MESSAGE_GROUP_STATUS_RESCHEDULED" | "CAMPAIGN_MESSAGE_GROUP_STATUS_SCHEDULED" | "CAMPAIGN_MESSAGE_GROUP_STATUS_SCHEDULED_DRAFT" | "CAMPAIGN_MESSAGE_GROUP_STATUS_SENDING" | "CAMPAIGN_MESSAGE_GROUP_STATUS_SEND_ERROR" | "CAMPAIGN_MESSAGE_GROUP_STATUS_SENT" | "CAMPAIGN_MESSAGE_GROUP_STATUS_UNKNOWN" | "CAMPAIGN_MESSAGE_GROUP_STATUS_UNSAVED_DRAFT" | "%future added value";
export type CampaignScheduleType = "CAMPAIGN_SCHEDULE_TYPE_INSTANT" | "CAMPAIGN_SCHEDULE_TYPE_SUBSCRIBER_LOCAL_TIME" | "CAMPAIGN_SCHEDULE_TYPE_SUBSCRIBER_OPTIMIZED_SEND_TIME" | "CAMPAIGN_SCHEDULE_TYPE_UNKNOWN" | "%future added value";
export type ComposeCampaignStatus = "COMPOSE_CAMPAIGN_STATUS_DRAFT" | "COMPOSE_CAMPAIGN_STATUS_SCHEDULED" | "COMPOSE_CAMPAIGN_STATUS_SENDING_STARTED" | "COMPOSE_CAMPAIGN_STATUS_SENT" | "COMPOSE_CAMPAIGN_STATUS_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type usePaginatedCampaigns_TargetingCommon_Query$data = {
  readonly composeCampaigns: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly campaignMessageGroups: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly messageMembers: ReadonlyArray<{
                readonly composeMessage: {
                  readonly messageId: number;
                  readonly name: string;
                };
              }>;
              readonly name: string;
              readonly scheduleType: CampaignScheduleType;
              readonly startTime: SerializedDateTime | null;
              readonly status: CampaignMessageGroupStatus;
            };
          }>;
        } | null;
        readonly campaignStatus: ComposeCampaignStatus;
        readonly company: {
          readonly timezone: string;
        } | null;
        readonly id: string;
        readonly name: string;
        readonly updated: SerializedDateTime;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string;
      readonly hasNextPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "usePaginatedCampaigns_TargetingCommon_Query";
};
export type usePaginatedCampaigns_TargetingCommon_Query$key = {
  readonly " $data"?: usePaginatedCampaigns_TargetingCommon_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePaginatedCampaigns_TargetingCommon_Query">;
};

import usePaginatedCampaigns_TargetingCommon_Query_refetchable_graphql from './usePaginatedCampaigns_TargetingCommon_Query_refetchable.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "composeCampaigns"
],
v1 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": usePaginatedCampaigns_TargetingCommon_Query_refetchable_graphql
    }
  },
  "name": "usePaginatedCampaigns_TargetingCommon_Query",
  "selections": [
    {
      "alias": "composeCampaigns",
      "args": [
        {
          "kind": "Variable",
          "name": "companyId",
          "variableName": "companyId"
        },
        (v1/*: any*/)
      ],
      "concreteType": "ComposeCampaignsConnection",
      "kind": "LinkedField",
      "name": "__usePaginatedCampaigns_TargetingCommon_Query_composeCampaigns_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ComposeCampaignEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ComposeCampaign",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "campaignStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Company",
                  "kind": "LinkedField",
                  "name": "company",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timezone",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    (v1/*: any*/)
                  ],
                  "concreteType": "CampaignMessageGroupsConnection",
                  "kind": "LinkedField",
                  "name": "campaignMessageGroups",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CampaignMessageGroupEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CampaignMessageGroup",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CampaignMessageMember",
                              "kind": "LinkedField",
                              "name": "messageMembers",
                              "plural": true,
                              "selections": [
                                {
                                  "kind": "RequiredField",
                                  "field": {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ComposeMessage",
                                    "kind": "LinkedField",
                                    "name": "composeMessage",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      {
                                        "alias": "messageId",
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "internalId",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  "action": "THROW",
                                  "path": "composeCampaigns.edges.node.campaignMessageGroups.edges.node.messageMembers.composeMessage"
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "status",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "startTime",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "scheduleType",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "4cbd15f023daf09e284117634105ca5e";

export default node;
