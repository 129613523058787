/**
 * @generated SignedSource<<83f534b444d006acfa1c1f9f2d85b40a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type Feature = "BRIDGE_IDENTIFIERS" | "CUSTOMER_SERVICE_OPT_IN" | "DYNAMIC_COUPON_GENERATION" | "FEATURE_ADDITIONAL_SETUP_LINK" | "FEATURE_ATTENTIVE_LOYALTY_SIGNUP" | "FEATURE_AUTO_INSTALL_TAG" | "FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS" | "FEATURE_CREATE_TRANSACTIONAL_SUBSCRIPTIONS" | "FEATURE_EMAIL_OPT_OUT_SYNC" | "FEATURE_EXPORT_SEGMENTS" | "FEATURE_GENERATE_HOOK" | "FEATURE_INGEST_AD_DATA" | "FEATURE_INGEST_LEADS" | "FEATURE_INGEST_TAG_EVENTS" | "FEATURE_INGEST_USER_DATA" | "FEATURE_MARKETING_EMAIL_MESSAGE_SEND" | "FEATURE_POSTBACK_USER_ATTRIBUTES" | "FEATURE_POSTBACK_USER_DATA" | "FEATURE_POST_SUBSCRIBER_OPT_IN" | "FEATURE_POST_SUBSCRIBER_OPT_OUT" | "FEATURE_PURCHASE_PRODUCT" | "FEATURE_REFUND_SYNC" | "FEATURE_SETUP_REDIRECT_SOURCE" | "FEATURE_SUBSCRIPTION_MESSAGING" | "FEATURE_SURVEY_MESSAGE" | "FEATURE_SYNC_ABANDONED_CHECKOUTS" | "FEATURE_SYNC_ACCOUNT_PROPERTIES" | "FEATURE_SYNC_ATTENTIVE_TO_ESP_EMAIL_OPT_OUTS_VIA_SFTP" | "FEATURE_SYNC_EMAIL_EVENTS" | "FEATURE_SYNC_EMAIL_SUBSCRIBERS" | "FEATURE_SYNC_ESP_TO_ATTENTIVE_EMAIL_OPT_OUTS_VIA_SFTP" | "FEATURE_SYNC_LIST_COUNT" | "FEATURE_SYNC_LIST_IDENTIFIERS" | "FEATURE_SYNC_LOYALTY_EVENTS" | "FEATURE_SYNC_ORDER_EVENTS" | "FEATURE_SYNC_PRODUCT_COLLECTIONS" | "FEATURE_SYNC_PURCHASES" | "FEATURE_SYNC_RECURRING_PURCHASES" | "FEATURE_SYNC_REVIEWS" | "FEATURE_SYNC_SMS_CONSENT" | "FEATURE_SYNC_THIRD_PARTY_CAMPAIGNS" | "FEATURE_SYNC_THIRD_PARTY_JOURNEYS" | "FEATURE_SYNC_USER_PROFILES" | "FEATURE_TEMPLATIZED_POSTBACK" | "FEATURE_TRIGGER_VENDOR_EMAIL_SEND" | "FEATURE_UNKNOWN" | "FORWARD_MESSAGE_CUSTOMER_SERVICE" | "INGEST_TRANSACTIONAL_EVENTS" | "POSTBACK_EMAILS" | "POST_WITH_PHONE" | "SCRUB_MESSAGE_SIGNATURE" | "SYNC_HISTORICAL_PURCHASES" | "SYNC_PRODUCTS" | "SYNC_THIRD_PARTY_LISTS" | "SYNC_THIRD_PARTY_SEGMENTS" | "THIRD_PARTY_PROCESSOR_FORWARDER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature$data = {
  readonly enabled: boolean;
  readonly externalVendorFeature: {
    readonly type: Feature;
  } | null;
  readonly " $fragmentType": "getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature";
};
export type getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature$key = {
  readonly " $data"?: getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature$data;
  readonly " $fragmentSpreads": FragmentRefs<"getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getIntegrationFeature_TargetingCommon_CompanyVendorIntegrationFeature"
};

(node as any).hash = "d53f586ec4096a4584bd88779cc897d8";

export default node;
