/**
 * @generated SignedSource<<149e3bc2ea8042285efe4323bedded09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteCustomAttributeTargetingCommonMutation$variables = {
  companyId: string;
  customPropertyId: string;
};
export type DeleteCustomAttributeTargetingCommonMutation$data = {
  readonly deleteCustomPropertyDefinition: {
    readonly __typename: "DeleteCustomPropertyDefinitionPayload";
    readonly customPropertyDefinition: {
      readonly __typename: "CustomPropertyDefinition";
      readonly id: string;
      readonly name: string;
    };
  } | null;
};
export type DeleteCustomAttributeTargetingCommonMutation$rawResponse = {
  readonly deleteCustomPropertyDefinition: {
    readonly __typename: "DeleteCustomPropertyDefinitionPayload";
    readonly customPropertyDefinition: {
      readonly __typename: "CustomPropertyDefinition";
      readonly id: string;
      readonly name: string;
    };
  } | null;
};
export type DeleteCustomAttributeTargetingCommonMutation = {
  rawResponse: DeleteCustomAttributeTargetingCommonMutation$rawResponse;
  response: DeleteCustomAttributeTargetingCommonMutation$data;
  variables: DeleteCustomAttributeTargetingCommonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customPropertyId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
          },
          {
            "kind": "Variable",
            "name": "customPropertyDefinitionId",
            "variableName": "customPropertyId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "DeleteCustomPropertyDefinitionPayload",
    "kind": "LinkedField",
    "name": "deleteCustomPropertyDefinition",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomPropertyDefinition",
        "kind": "LinkedField",
        "name": "customPropertyDefinition",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteCustomAttributeTargetingCommonMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteCustomAttributeTargetingCommonMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "12b882485e835fe2767ccbefea1e653e",
    "id": null,
    "metadata": {},
    "name": "DeleteCustomAttributeTargetingCommonMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteCustomAttributeTargetingCommonMutation(\n  $companyId: ID!\n  $customPropertyId: ID!\n) {\n  deleteCustomPropertyDefinition(input: {companyId: $companyId, customPropertyDefinitionId: $customPropertyId}) {\n    __typename\n    customPropertyDefinition {\n      __typename\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "42bf9d3567b9c5d885f716cbfd3b4257";

export default node;
