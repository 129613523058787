/**
 * @generated SignedSource<<94617becff2b93dc72fd2e6869422819>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttributeDetails_TargetingCommon_CustomPropertyDefinition$data = {
  readonly updated: SerializedDateTime;
  readonly " $fragmentType": "AttributeDetails_TargetingCommon_CustomPropertyDefinition";
};
export type AttributeDetails_TargetingCommon_CustomPropertyDefinition$key = {
  readonly " $data"?: AttributeDetails_TargetingCommon_CustomPropertyDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"AttributeDetails_TargetingCommon_CustomPropertyDefinition">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AttributeDetails_TargetingCommon_CustomPropertyDefinition",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": null
    }
  ],
  "type": "CustomPropertyDefinition",
  "abstractKey": null
};

(node as any).hash = "8f2c506427e1c91ab003965e46fe433c";

export default node;
