/**
 * @generated SignedSource<<340b1dfe11aa4d56dab2d42b63948980>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCompanyApps_TargetingCommon_Company$data = {
  readonly builtApplications: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly internalId: number;
        readonly listing: {
          readonly imageUrl: {
            readonly value: string;
          } | null;
        };
        readonly name: string;
      };
    }>;
  } | null;
  readonly installedApplications: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly application: {
          readonly id: string;
          readonly internalId: number;
          readonly listing: {
            readonly imageUrl: {
              readonly value: string;
            } | null;
          };
          readonly name: string;
        } | null;
        readonly id: string;
      };
    }>;
  } | null;
  readonly " $fragmentType": "useCompanyApps_TargetingCommon_Company";
};
export type useCompanyApps_TargetingCommon_Company$key = {
  readonly " $data"?: useCompanyApps_TargetingCommon_Company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCompanyApps_TargetingCommon_Company">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "internalId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ApplicationListing",
    "kind": "LinkedField",
    "name": "listing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Url",
        "kind": "LinkedField",
        "name": "imageUrl",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCompanyApps_TargetingCommon_Company",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "ApplicationConnection",
      "kind": "LinkedField",
      "name": "builtApplications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ApplicationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Application",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "builtApplications(first:100)"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "InstalledApplicationConnection",
      "kind": "LinkedField",
      "name": "installedApplications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InstalledApplicationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstalledApplication",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Application",
                  "kind": "LinkedField",
                  "name": "application",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "installedApplications(first:100)"
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "ac5b749b87e8ad4483cdb6cd1af96609";

export default node;
