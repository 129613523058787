/**
 * @generated SignedSource<<fdd435c428a6784979fdfddde2f49e72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CustomPropertyType = "TYPE_ARRAY_OBJECT" | "TYPE_BOOL" | "TYPE_DATE" | "TYPE_DATETIME" | "TYPE_ENUM" | "TYPE_LONG" | "TYPE_NUMBER" | "TYPE_OBJECT" | "TYPE_STRING" | "TYPE_TIMESTAMP" | "TYPE_UNKNOWN" | "%future added value";
export type PostCustomPropertyDefinitionInput = {
  enumValues?: ReadonlyArray<string> | null;
  isDeleted?: boolean | null;
  name: string;
  propertyType: CustomPropertyType;
};
export type CreateCustomAttributeTargetingCommonMutation$variables = {
  companyId: string;
  newFields: PostCustomPropertyDefinitionInput;
};
export type CreateCustomAttributeTargetingCommonMutation$data = {
  readonly createCustomPropertyDefinition: {
    readonly __typename: "CreateCustomPropertyDefinitionPayload";
    readonly customPropertyDefinition: {
      readonly __typename: "CustomPropertyDefinition";
      readonly customPropertyId: {
        readonly internalId: string;
      };
      readonly id: string;
      readonly name: string;
    };
  } | null;
};
export type CreateCustomAttributeTargetingCommonMutation$rawResponse = {
  readonly createCustomPropertyDefinition: {
    readonly __typename: "CreateCustomPropertyDefinitionPayload";
    readonly customPropertyDefinition: {
      readonly __typename: "CustomPropertyDefinition";
      readonly customPropertyId: {
        readonly internalId: string;
      };
      readonly id: string;
      readonly name: string;
    };
  } | null;
};
export type CreateCustomAttributeTargetingCommonMutation = {
  rawResponse: CreateCustomAttributeTargetingCommonMutation$rawResponse;
  response: CreateCustomAttributeTargetingCommonMutation$data;
  variables: CreateCustomAttributeTargetingCommonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newFields"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "companyId",
            "variableName": "companyId"
          },
          {
            "kind": "Variable",
            "name": "newFields",
            "variableName": "newFields"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "CreateCustomPropertyDefinitionPayload",
    "kind": "LinkedField",
    "name": "createCustomPropertyDefinition",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomPropertyDefinition",
        "kind": "LinkedField",
        "name": "customPropertyDefinition",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomPropertyID",
            "kind": "LinkedField",
            "name": "customPropertyId",
            "plural": false,
            "selections": [
              {
                "alias": "internalId",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCustomAttributeTargetingCommonMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCustomAttributeTargetingCommonMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "943b56616d6841429d5354cc3e84fa17",
    "id": null,
    "metadata": {},
    "name": "CreateCustomAttributeTargetingCommonMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCustomAttributeTargetingCommonMutation(\n  $companyId: ID!\n  $newFields: PostCustomPropertyDefinitionInput!\n) {\n  createCustomPropertyDefinition(input: {companyId: $companyId, newFields: $newFields}) {\n    __typename\n    customPropertyDefinition {\n      __typename\n      id\n      name\n      customPropertyId {\n        internalId: id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9db393690fdf1c98434067c06fc1c41";

export default node;
