/**
 * @generated SignedSource<<0b26f0f6c8d0d294ecff542e8176769b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type downloadSegmentUsages_TargetingCommon_CustomPropertyDefinition$data = {
  readonly usages: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"downloadSegmentUsages_formatCSVRow_TargetingCommon_Segment">;
  }>;
  readonly " $fragmentType": "downloadSegmentUsages_TargetingCommon_CustomPropertyDefinition";
};
export type downloadSegmentUsages_TargetingCommon_CustomPropertyDefinition$key = {
  readonly " $data"?: downloadSegmentUsages_TargetingCommon_CustomPropertyDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"downloadSegmentUsages_TargetingCommon_CustomPropertyDefinition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "downloadSegmentUsages_TargetingCommon_CustomPropertyDefinition"
};

(node as any).hash = "180bf49ccfb9064c841aacd7f2952390";

export default node;
