/**
 * @generated SignedSource<<91c8c9591cda253dc6f39d62f61d5823>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JourneyRow_TargetingCommon_Journey$data = {
  readonly id: string;
  readonly internalId: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"JourneyRow_getJourneySecondaryText_TargetingCommon_Journey" | "JourneyStatusPill_TargetingCommon_Journey">;
  readonly " $fragmentType": "JourneyRow_TargetingCommon_Journey";
};
export type JourneyRow_TargetingCommon_Journey$key = {
  readonly " $data"?: JourneyRow_TargetingCommon_Journey$data;
  readonly " $fragmentSpreads": FragmentRefs<"JourneyRow_TargetingCommon_Journey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JourneyRow_TargetingCommon_Journey",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JourneyStatusPill_TargetingCommon_Journey"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "JourneyRow_getJourneySecondaryText_TargetingCommon_Journey",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JourneyRevision",
          "kind": "LinkedField",
          "name": "publishedRevision",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publishStartTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "JourneyRevision",
          "kind": "LinkedField",
          "name": "draftRevision",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastUpdatedTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Journey",
  "abstractKey": null
};

(node as any).hash = "0dcc7d442a4621f184754ab787895cb2";

export default node;
