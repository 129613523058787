/**
 * @generated SignedSource<<6c0ff579dd1b2e432ba342d5283e9c06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type CustomPropertyType = "TYPE_ARRAY_OBJECT" | "TYPE_BOOL" | "TYPE_DATE" | "TYPE_DATETIME" | "TYPE_ENUM" | "TYPE_LONG" | "TYPE_NUMBER" | "TYPE_OBJECT" | "TYPE_STRING" | "TYPE_TIMESTAMP" | "TYPE_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition$data = {
  readonly __typename: "CustomPropertyDefinition";
  readonly created: SerializedDateTime;
  readonly customPropertyId: {
    readonly id: string;
  };
  readonly id: string;
  readonly name: string;
  readonly propertyType: CustomPropertyType;
  readonly " $fragmentType": "useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition";
};
export type useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition$key = {
  readonly " $data"?: useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition"
};

(node as any).hash = "956a13b3aba66f85b9a3d923ba5f0981";

export default node;
