/**
 * @generated SignedSource<<62dc5896d6be3c4068c95655a4f402c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JourneyMessageDialog_TargetingCommon_Company$data = {
  readonly " $fragmentSpreads": FragmentRefs<"usePaginatedJourneys_TargetingCommon_Company">;
  readonly " $fragmentType": "JourneyMessageDialog_TargetingCommon_Company";
};
export type JourneyMessageDialog_TargetingCommon_Company$key = {
  readonly " $data"?: JourneyMessageDialog_TargetingCommon_Company$data;
  readonly " $fragmentSpreads": FragmentRefs<"JourneyMessageDialog_TargetingCommon_Company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JourneyMessageDialog_TargetingCommon_Company",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePaginatedJourneys_TargetingCommon_Company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "f6e8c22525a8a51105a8d5c9ffc2dfd6";

export default node;
