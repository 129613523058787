/**
 * @generated SignedSource<<c7b5d6ba88b7065dbae641111374ca6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch$variables = {
  id: string;
};
export type ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition">;
  } | null;
};
export type ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch = {
  response: ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch$data;
  variables: ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Segment",
                "kind": "LinkedField",
                "name": "usages",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CustomPropertyDefinition",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da64c1294371aa28341561fc24e88479",
    "id": null,
    "metadata": {},
    "name": "ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch",
    "operationKind": "query",
    "text": "query ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition\n    id\n  }\n}\n\nfragment ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition on CustomPropertyDefinition {\n  usages {\n    id\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "f7d71bf209badfe9cd1a83852f5dc9e0";

export default node;
