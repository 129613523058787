/**
 * @generated SignedSource<<36dafbcb4263e8df723f90c6a6ddbf48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomPropertyType = "TYPE_ARRAY_OBJECT" | "TYPE_BOOL" | "TYPE_DATE" | "TYPE_DATETIME" | "TYPE_ENUM" | "TYPE_LONG" | "TYPE_NUMBER" | "TYPE_OBJECT" | "TYPE_STRING" | "TYPE_TIMESTAMP" | "TYPE_UNKNOWN" | "%future added value";
export type CustomAttributeList_TargetingCommon_Query$variables = {
  after?: string | null;
  companyId: string;
};
export type CustomAttributeList_TargetingCommon_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CustomAttributeList_TargetingCommon_Attributes_query">;
};
export type CustomAttributeList_TargetingCommon_Query$rawResponse = {
  readonly company: {
    readonly __typename: "Company";
    readonly id: string;
    readonly userPropertyDefinitionsV2: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly __typename: "CustomPropertyDefinition";
          readonly __isNode: "CustomPropertyDefinition";
          readonly created: SerializedDateTime;
          readonly customPropertyId: {
            readonly id: string;
          };
          readonly id: string;
          readonly name: string;
          readonly propertyType: CustomPropertyType;
        } | {
          readonly __typename: string;
          readonly __isNode: string;
          readonly id: string;
        };
      }>;
      readonly pageInfo: {
        readonly endCursor: string;
        readonly hasNextPage: boolean;
      };
    } | null;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type CustomAttributeList_TargetingCommon_Query = {
  rawResponse: CustomAttributeList_TargetingCommon_Query$rawResponse;
  response: CustomAttributeList_TargetingCommon_Query$data;
  variables: CustomAttributeList_TargetingCommon_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "includeDeleted": false,
      "includeInternalProperties": false,
      "source": "USER_PROPERTY_SOURCE_CUSTOM"
    }
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v5 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomAttributeList_TargetingCommon_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CustomAttributeList_TargetingCommon_Attributes_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CustomAttributeList_TargetingCommon_Query",
    "selections": [
      {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "UserPropertyDefinitionConnectionV2",
                "kind": "LinkedField",
                "name": "userPropertyDefinitionsV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserPropertyDefinitionEdgeV2",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CustomPropertyID",
                                "kind": "LinkedField",
                                "name": "customPropertyId",
                                "plural": false,
                                "selections": (v5/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "propertyType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "created",
                                "storageKey": null
                              }
                            ],
                            "type": "CustomPropertyDefinition",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v5/*: any*/),
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "filter"
                ],
                "handle": "connection",
                "key": "useCustomAttributes_TargetingCommon_Attributes_connection_userPropertyDefinitionsV2",
                "kind": "LinkedHandle",
                "name": "userPropertyDefinitionsV2"
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c40cfd86cc0eb39f8a7e3183e6f5fba6",
    "id": null,
    "metadata": {},
    "name": "CustomAttributeList_TargetingCommon_Query",
    "operationKind": "query",
    "text": "query CustomAttributeList_TargetingCommon_Query(\n  $companyId: ID!\n  $after: String\n) {\n  ...CustomAttributeList_TargetingCommon_Attributes_query\n}\n\nfragment CustomAttributeList_TargetingCommon_Attributes_query on Query {\n  ...useCustomAttributes_TargetingCommon_Attributes_query\n}\n\nfragment useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition on CustomPropertyDefinition {\n  __typename\n  id\n  name\n  propertyType\n  created\n  customPropertyId {\n    id\n  }\n}\n\nfragment useCustomAttributes_TargetingCommon_Attributes_query on Query {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      id\n      userPropertyDefinitionsV2(first: 100, after: $after, filter: {includeDeleted: false, includeInternalProperties: false, source: USER_PROPERTY_SOURCE_CUSTOM}) {\n        edges {\n          node {\n            __typename\n            ... on CustomPropertyDefinition {\n              id\n              customPropertyId {\n                id\n              }\n            }\n            ...useCustomAttributes_TargetingCommon_Attribute_CustomPropertyDefinition\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4288863a7602259ff25d4746ab5d4f0";

export default node;
