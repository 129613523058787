/**
 * @generated SignedSource<<1b9db4abc3d2e9491cd3dbd3ff04a898>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition$data = {
  readonly id: string;
  readonly usages: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentType": "ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition";
};
export type ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition$key = {
  readonly " $data"?: ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition">;
};

import ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch_graphql from './ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition_Refetch_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "ExistingCustomAttributeDialog_usages_TargetingCommon_CustomPropertyDefinition",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Segment",
      "kind": "LinkedField",
      "name": "usages",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "CustomPropertyDefinition",
  "abstractKey": null
};
})();

(node as any).hash = "f7d71bf209badfe9cd1a83852f5dc9e0";

export default node;
