/**
 * @generated SignedSource<<38c6a4e552be4ae31a43cb78f2faa0ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hasSegmentUsages_TargetingCommon_CustomPropertyDefinition$data = {
  readonly usages: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentType": "hasSegmentUsages_TargetingCommon_CustomPropertyDefinition";
};
export type hasSegmentUsages_TargetingCommon_CustomPropertyDefinition$key = {
  readonly " $data"?: hasSegmentUsages_TargetingCommon_CustomPropertyDefinition$data;
  readonly " $fragmentSpreads": FragmentRefs<"hasSegmentUsages_TargetingCommon_CustomPropertyDefinition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hasSegmentUsages_TargetingCommon_CustomPropertyDefinition"
};

(node as any).hash = "29878d39d6539c6d963f1072b7d51b77";

export default node;
