/**
 * @generated SignedSource<<7f3fc567d69fbdb559ba3d56216b7a19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignDialogBody_TargetingCommon_Query$data = {
  readonly allCampaigns: {
    readonly totalCount: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"usePaginatedCampaigns_TargetingCommon_Query">;
  readonly " $fragmentType": "CampaignDialogBody_TargetingCommon_Query";
};
export type CampaignDialogBody_TargetingCommon_Query$key = {
  readonly " $data"?: CampaignDialogBody_TargetingCommon_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignDialogBody_TargetingCommon_Query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "cursor"
},
v1 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "composeCampaignsFilter"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "count"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    },
    {
      "kind": "RootArgument",
      "name": "composeCampaignsFilter"
    },
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignDialogBody_TargetingCommon_Query",
  "selections": [
    {
      "alias": "allCampaigns",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "companyId",
          "variableName": "companyId"
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "concreteType": "ComposeCampaignsConnection",
      "kind": "LinkedField",
      "name": "composeCampaigns",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "usePaginatedCampaigns_TargetingCommon_Query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "280301a5b2ee473fc5fcbdf67edc5b5a";

export default node;
