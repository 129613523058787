/**
 * @generated SignedSource<<f65d70f983c38273c770109135c0098a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type downloadSegmentUsages_formatCSVRow_TargetingCommon_Segment$data = {
  readonly internalId: number;
  readonly name: string;
  readonly " $fragmentType": "downloadSegmentUsages_formatCSVRow_TargetingCommon_Segment";
};
export type downloadSegmentUsages_formatCSVRow_TargetingCommon_Segment$key = {
  readonly " $data"?: downloadSegmentUsages_formatCSVRow_TargetingCommon_Segment$data;
  readonly " $fragmentSpreads": FragmentRefs<"downloadSegmentUsages_formatCSVRow_TargetingCommon_Segment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "downloadSegmentUsages_formatCSVRow_TargetingCommon_Segment"
};

(node as any).hash = "927e472b6bf35547c42edb8073d5f697";

export default node;
